import { IGridSettings, IImageData, IImageSettings, Image } from '@msdyn365-commerce/core';
import classnames from 'classnames';
import * as React from 'react';
export type PictureLoadFailureBehavior = 'default' | 'hide' | 'empty';

export interface IPictureState extends React.ComponentState {
    showHoverImg: boolean;
}
export interface IPictureProps {
    loadFailureBehavior?: PictureLoadFailureBehavior;
    image1?: string;
    image2?: string;
    emptyImageUrl?: string;
    altText?: string;
    gridSettings: IGridSettings;
    imageSettings?: IImageSettings;
    className?: string;
}
/** The Picture component will return picture tag for an image based on ImageResize properties and settings needed to resize the image */
export default class CittaPicture extends React.PureComponent<IPictureProps, IPictureState> {

    public render(): JSX.Element | null {
        const { image1 = '', image2 = '', altText, gridSettings, imageSettings } = this.props;
        const img1: IImageData = {
            src: image1,
            altText: altText ? altText : ''
        };
        const img2: IImageData = {
            src: image2,
            altText: altText ? altText : ''
        };
        const imageProps = {
            gridSettings: gridSettings,
            imageSettings: imageSettings
        };
        return (
            <div className='images-container'>
                <Image
                    {...img1}
                    {...imageProps}
                    loadFailureBehavior='empty'
                    className={classnames('image1', this.props.className)}
                    onMouseOver={this.handleMoveHover}
                />
                <Image
                    {...img2}
                    {...imageProps}
                    loadFailureBehavior='hide'
                    className={classnames((this.state?.showHoverImg ? 'image2 hoverImg ' : 'image2 hoverImg hideImg '), this.props.className)}
                    onMouseOut={this.handleMoveOut}
                />
            </div>
        );
    }

    private handleMoveHover = () => {
        this.setState({ showHoverImg: true });
    }

    private handleMoveOut = () => {
        this.setState({ showHoverImg: false });
    }
}
