import { generateImageUrl, getProductPageUrlSync } from '@msdyn365-commerce-modules/retail-actions';
import { format } from '@msdyn365-commerce-modules/utilities';
import { RatingComponent } from '@msdyn365-commerce/components';
import { IComponentProps, ICoreContext, IGridSettings, IImageSettings } from '@msdyn365-commerce/core';
import { ProductPrice, ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import * as React from 'react';
import CittaPicture from '../../../components/citta-picture';

import PriceComponent from '../../../components/price-component';

export interface IProductComponentProps extends IComponentProps<{ product?: ProductSearchResult }> {
    className?: string;
    imageSettings?: IImageSettings;
    savingsText?: string;
    freePriceText?: string;
    originalPriceText?: string;
    currentPriceText?: string;
    ratingAriaLabel?: string;
    salesLineTagKey?: string;
    tagsToIgnore?: string;
}

/**
 *
 * The ProductComponent renders the products.
 * @extends {React.PureComponent<IProductComponentProps>}
 */
class ProductComponent extends React.Component<IProductComponentProps> {

    constructor(props: IProductComponentProps) {
        super(props);
    }

    public render(): JSX.Element | null {
        const product = this.props.data.product;
        if (!product) {
            return null;
        }
        let imageOrientation: string = '';
        let onlineTags: string = '';
        let salesLineTag: string = '';
        let productImage1: string = '';
        let productImage2: string = '';
        let stockAvailabilityPropertyText: string = '';
        let stockAvailabilities: any[] = [];

        if (product.ExtensionProperties!.length > 0) {
            product.ExtensionProperties!.map(property => {
                if (property.Key === 'ProductImage1') {
                    productImage1 = property.Value!.StringValue!;
                } else if (property.Key === 'ProductImage2') {
                    productImage2 = property.Value!.StringValue!;
                }
                else if (property.Key === 'ProductAvailability') {
                    stockAvailabilities.push({name: property.Value?.StringValue, value:  property.Value?.IntegerValue});
                }
            });
        }
        if (product.AttributeValues!.length > 0) {
            product.AttributeValues!.map(property => {
                if (property.Name === 'Image Orientation') {
                    imageOrientation = property.TextValue!;
                } else if (property.Name === 'Online Tags' || property.Name === "Online Tags B2BN" || property.Name === "Online Tags B2BA" || property.Name === "Online Tags B2BI") {
                    onlineTags = property.TextValue!;
                    if (this.props.tagsToIgnore && onlineTags) {
                        let tags  = this.props.tagsToIgnore.split('|');
                        if (tags.includes(onlineTags)) {
                            onlineTags = '';
                        }
                    }
                } else if (property.Name === this.props.salesLineTagKey) {
                    salesLineTag = property.TextValue!;
                }
            });
        }

        const image1 = generateImageUrl(productImage1, this.props.context.request.apiSettings);
        const image2 = generateImageUrl(productImage2, this.props.context.request.apiSettings);

        const productUrl = getProductPageUrlSync(product.Name || '', product.RecordId, this.props.context && this.props.context.actionContext, undefined);

        // Construct telemetry attribute to render
        const attribute = this.props.context
            && this.props.context.telemetry
            && this.props.context.telemetry.setTelemetryAttribute
            && this.props.context.telemetry.setTelemetryAttribute(product.RecordId.toString(), {
                pid: product.RecordId,
                pname: product.Name,
                mname: this.props.id
            }
            );
        const productImageSettings = imageOrientation === 'Landscape' ? this._getLandscapeImageSettings(this.props.imageSettings) : this.props.imageSettings;
        return (
            <a href={productUrl} aria-label={this._renderLabel(product.Name, this.props.context.cultureFormatter.formatCurrency(product.Price), product.AverageRating, this.props.ratingAriaLabel)} className='msc-product' {...attribute}>
                <div className={(onlineTags && onlineTags !== '') ? 'product-placement__item-imagetag' : 'product-placement__item-imagetagnone'} style={onlineTags.toLocaleLowerCase().includes('essentials') ? {backgroundColor: '#96999b'}: {}}>{onlineTags}</div>
                <div className='msc-product__image'>
                    {this._renderProductPlacementImage(productImageSettings, this.props.context.request.gridSettings, product.PrimaryImageUrl, image1, image2, product.Name)}
                </div>
                <div className='msc-product__details'>
                    <h4 className='msc-product__title'>{product.Name}</h4>
                    {this._renderPrice(this.props.context, this.props.typeName, this.props.id, product.BasePrice, product.Price, this.props.savingsText, this.props.freePriceText, this.props.originalPriceText, this.props.currentPriceText)}
                    {this._renderSalesLineTag(salesLineTag)}
                    {this._renderDescription(product.Description)}
                    {!this.props.context.app.config.hideRating && this._renderRating(this.props.context, this.props.typeName, this.props.id, product.AverageRating, product.TotalRatings, this.props.ratingAriaLabel)}
                    {stockAvailabilityPropertyText}
                    <div className="msc-product__availabilities">
                        {this._renderAvailabilities(stockAvailabilities)}
                    </div>
                </div>
            </a>
        );
    }

    private _renderAvailabilities(stockAvailabilities: any[]) {
        return stockAvailabilities && stockAvailabilities.map(sA => <div className="msc-product__availabilities_product">
                <span className={sA.value > 0 ? 'in-stock' : 'out-of-stock'}>{sA.name.match(/\b(\w)/g).join('')}</span>
            </div>
        )
    }

    private _renderLabel(name?: string, price?: string, rating?: number, ratingAriaLabel?: string): string {
        let ratingLabel = '';

        name = name || '';
        price = price || '';

        if (rating) {
            const roundedRating = rating.toFixed(2);
            ratingLabel = format(ratingAriaLabel || '', roundedRating, '5');
        }

        return (`${name} ${price} ${ratingLabel}`);
    }

    private _renderSalesLineTag(salesLineTag?: string): JSX.Element | null {
        if (salesLineTag! && salesLineTag !== '') {
            return (
                <div className='product-placement__item-saleslinetag'>{salesLineTag}</div>
            );
        }
        return null;
    }

    private _renderDescription(description?: string): JSX.Element | null {
        return <p className='msc-product__text'>{description}</p>;
    }

    private _renderRating(context: ICoreContext, typeName: string, id: string, avgRating?: number, totalRatings?: number, ariaLabel?: string): JSX.Element | null {
        if (!avgRating) {
            return null;
        }

        const numRatings = totalRatings && totalRatings.toString() || undefined;

        return (
            <RatingComponent
                context={context}
                id={id}
                typeName={typeName}
                avgRating={avgRating}
                ratingCount={numRatings}
                readOnly={true}
                ariaLabel={ariaLabel || ''}
                data={{}}
            />
        );
    }

    private _renderPrice(context: ICoreContext, typeName: string, id: string, basePrice?: number, adjustedPrice?: number, savingsText?: string, freePriceText?: string, originalPriceText?: string, currentPriceText?: string): JSX.Element | null {
        const price: ProductPrice = {
            BasePrice: basePrice,
            AdjustedPrice: adjustedPrice,
            CustomerContextualPrice: adjustedPrice
        };

        return (
            <PriceComponent
                context={context}
                id={id}
                typeName={typeName}
                data={{ price: price }}
                // @ts-ignore
                savingsText={savingsText}
                freePriceText={freePriceText}
                originalPriceText={originalPriceText}
            />
        );
    }

    private _renderProductPlacementImage(imageSettings?: IImageSettings, gridSettings?: IGridSettings, imageUrl?: string, image1?: string, image2?: string, altText?: string): JSX.Element | null {
        if (!imageUrl || !gridSettings || !imageSettings) {
            return null;
        }
        return (
            <CittaPicture
                className='product-placement__item-image'
                image1={image1 ? image1 : imageUrl}
                image2={image2 ? image2 : ''}
                altText={altText}
                gridSettings={gridSettings}
                imageSettings={imageSettings}
                loadFailureBehavior='empty'
            />
        );
    }

    private _getLandscapeImageSettings(cmsImageSettings?: IImageSettings): IImageSettings {
        // tslint:disable-next-line: no-unnecessary-local-variable
        const landscapeImageSettings: IImageSettings = {
            viewports: {
                xs: { q: 'w=735&h=481&q=80&m=6&f=jpg', w: 735, h: 481 },
                sm: { q: 'w=467&h=303&q=80&m=6&f=jpg', w: 467, h: 303 },
                md: { q: 'w=563&h=366&q=80&m=6&f=jpg', w: 563, h: 366 },
                lg: { q: 'w=636&h=451&q=80&m=6&f=jpg', w: 636, h: 451 },
                xl: { q: 'w=974&h=693&q=80&m=6&f=jpg', w: 974, h: 693 }
            },
            disableLazyLoad: cmsImageSettings && cmsImageSettings.disableLazyLoad,
            lazyload: cmsImageSettings && cmsImageSettings.lazyload
        };

        return landscapeImageSettings;
    }
}

export default ProductComponent;
