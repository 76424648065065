import { ICategoryHierarchyViewProps, ISearchResultContainerViewProps, ITitleViewProps, ISearchResultModalViewProps } from '@msdyn365-commerce-modules/search-result-container';
import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import { ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import * as React from 'react';
import CittaRefiners from '../../components/citta-search-result/citta-refiners';
// import CittaSortByOptions from '../../components/citta-search-result/citta-sortbyoptions';
import { ICittaSearchResultContainerProps } from './citta-search-result-container.props.autogenerated';
import ProductComponent from './components/product-component';

const SearchResultContainerView: React.FC<ISearchResultContainerViewProps & ICittaSearchResultContainerProps<{}>> = props => {
    const {
        SearchResultContainer,
        pagination,
        ProductsContainer,
        modalToggle,
        searchResultModal,
        TitleViewProps,
        categoryHierarchy,
        errorMessage,
        // @ts-ignore
        toggleModal
    } = props;

    return (
        <Module {...SearchResultContainer}>
            {renderCategoryHierarchy(categoryHierarchy)}
            <div className="filter-button-container">
                {modalToggle}
            </div>
            {createSearchResultModal(searchResultModal, props, toggleModal)}
            {renderTitle(TitleViewProps)}
            <Node {...ProductsContainer}>
                {errorMessage}
                {renderProducts(props)}
            </Node>
            {pagination}
        </Module>
    );
};

function renderProducts(props: ISearchResultContainerViewProps & ICittaSearchResultContainerProps<{}>): JSX.Element | null {
    const { config, context, resources } = props;
    const liClass = 'ms-product-search-result__item';
    const isB2B = props.context?.request?.app?.platform?.enableDefaultOrderQuantityLimits === 'b2b';
    const products = (isB2B ? props.data.listPageState.result && props.data.listPageState.result.activeProducts && props.data.listPageState.result.activeProducts.filter((item) => item.ItemId !=="GiftCard") : props.data.listPageState.result && props.data.listPageState.result.activeProducts) || [];
    if (!products || products.length === 0) {
        return null;
    }
    return (
        <ul className='list-unstyled'>
            {products.map((product: ProductSearchResult, index: number) => (
                <li className={`${liClass} ${getImageOrientation(product) === 'Landscape' ? 'product-placement__item-limage' : 'product-placement__item-pimage'}`} key={index} >
                    <ProductComponent
                        context={context}
                        imageSettings={config.imageSettings}
                        freePriceText={resources.priceFree}
                        originalPriceText={resources.originalPriceText}
                        currentPriceText={resources.currentPriceText}
                        ratingAriaLabel={resources.ratingAriaLabel}
                        id={props.id}
                        salesLineTagKey={config.salesLineTagKey}
                        typeName={props.typeName}
                        data={{ product: product }}
                        tagsToIgnore={config.tagsToIgnore}
                    />
                </li>
            ))}
        </ul>
    );
}

function getImageOrientation(product: ProductSearchResult): string {
    let imageOrientation: string = '';
    if (product.AttributeValues!.length > 0) {
        product.AttributeValues!.map(property => {
            if (property.Name === 'Image Orientation') {
                imageOrientation = property.TextValue!;
            }
        });
    }
    return imageOrientation;
}

const createSearchResultModal = (modalProps: ISearchResultModalViewProps, props: ISearchResultContainerViewProps & ICittaSearchResultContainerProps<{}>, toggleModal: Function): JSX.Element => {
    return React.cloneElement(modalProps.modal, {className: "category-refiner"}, null, createModalBody(modalProps, props, toggleModal))
};

const createModalBody = (modalProps: ISearchResultModalViewProps, props: ISearchResultContainerViewProps & ICittaSearchResultContainerProps<{}>, toggleModal: Function): JSX.Element | null => {
    return React.cloneElement(modalProps.modalBody, {}, null, renderRefineMenu(props, toggleModal));
};

function renderRefineMenu(props: ISearchResultContainerViewProps & ICittaSearchResultContainerProps<{}>, toggleModal: Function): JSX.Element {
    return (
        <CittaRefiners
            props={props}
            toggleModal={toggleModal}
        />
    );
}

// function renderSort(props: ISearchResultContainerViewProps & ICittaSearchResultContainerProps<{}>): JSX.Element {
//     return (
//         <CittaSortByOptions
//             props={props}
//         />
//     );
// }

const renderCategoryHierarchy = (props: ICategoryHierarchyViewProps): JSX.Element | null => {
    const { CategoryHierarchyContainer, categoryHierarchyList, categoryHierarchySeparator } = props;
    if (categoryHierarchyList) {
        return (
            <Node {...CategoryHierarchyContainer}>
                {categoryHierarchyList.map((category, index) => (
                    <React.Fragment key={index}>
                        {category}
                        {categoryHierarchyList && categoryHierarchyList[index + 1] && categoryHierarchySeparator}
                    </React.Fragment>
                ))}
            </Node>
        );
    }

    return null;
};

const renderTitle = (props: ITitleViewProps): JSX.Element | null => {
    const { title, TitleContainer } = props;
    if (title) {
        return (
            <Node {...TitleContainer}>
                <label style={{display: 'none'}} className="hide emarsys-collection-name">{title.titleText}</label>
                <h2>
                    {title.titleCount}
                </h2>
            </Node>
        );
    }
    return null;
};

// const renderTitleCount = (props: ITitleViewProps): JSX.Element | null => {
//     const { title, TitleContainer } = props;
//     if (title) {
//         return (
//             <Node {...TitleContainer}>
//                 <label className="hide emarsys-collection-name">{title.titleText}</label>
//                 <h5>
//                     {title.titleCount}
//                 </h5>
//             </Node>
//         );
//     }
//     return null;
// };

export default SearchResultContainerView;