import { getUpdatedRefinementCriteria, IRefineItemCommonProps, IRefineItemToggleNotification, ISearchResultContainerViewProps } from '@msdyn365-commerce-modules/search-result-container';
import { Button,ILabeledDropdownOption, INodeProps, Node } from '@msdyn365-commerce-modules/utilities';
import { IProductRefinerHierarchy } from '@msdyn365-commerce/commerce-entities';
import { SortColumn } from '@msdyn365-commerce/retail-proxy';
import { transaction } from 'mobx';
import * as React from 'react';
import { ICittaSearchResultContainerProps } from '../../modules/citta-search-result-container/citta-search-result-container.props.autogenerated';
import RefineSubmenu from './refine-submenu';
import { buildListPageUrl, getCurrentUrl } from './url-utils';
export interface ISortByViewProps {
    SortingContainer: INodeProps;
    sortByDropDown?: React.ReactNode;
}

export interface ISortByProps {
    props: ISearchResultContainerViewProps & ICittaSearchResultContainerProps<{}>;
}

export interface ISortByOptionsState {
    sortingState: ISortByCollectionState;
}

interface ISortByCollectionState {
    selectedSortByOption: ILabeledDropdownOption;
    dropdownOpen: boolean;
    pending: boolean;
}
export interface IRefineMenuViewProps {
    RefineMenuContainer: INodeProps;
    RefinerSectionContainer: INodeProps;
    refinersHeader: React.ReactNode;
    refiners?: React.ReactNode[];
}

const sortOptions = {
    sortByOptionRelevanceDesc: 'Ranking-Desc',
    sortByOptionRatingAsc: 'AverageRating-Asc',
    sortByOptionRatingDesc: 'AverageRating-Desc',
    sortByOptionNameAsc: 'Name-Asc',
    sortByOptionNameDesc: 'Name-Desc',
    sortByOptionPriceAsc: 'Price-Asc',
    sortByOptionPriceDesc: 'Price-Desc'
};

export interface IRefinerProps {
    props: ISearchResultContainerViewProps & ICittaSearchResultContainerProps<{}>;
    toggleModal: Function;
}
/** This component will be used to render Refiners on Citta category and search results page */
export default class CittaRefiners extends React.PureComponent<IRefinerProps> {
    private _refineItemCommonProps: IRefineItemCommonProps;
    
    private sortByDropdownOptions: ILabeledDropdownOption[] = [
        { key: sortOptions.sortByOptionRelevanceDesc, value: this.props.props.resources.sortByOptionRelevanceDesc },
        { key: sortOptions.sortByOptionNameAsc, value: this.props.props.resources.sortByOptionNameAsc },
        { key: sortOptions.sortByOptionNameDesc, value: this.props.props.resources.sortByOptionNameDesc },
        { key: sortOptions.sortByOptionPriceAsc, value: this.props.props.resources.sortByOptionPriceAsc },
        { key: sortOptions.sortByOptionPriceDesc, value: this.props.props.resources.sortByOptionPriceDesc },
    ];


    constructor(props: IRefinerProps) {
        super(props);
        const {
            placeholderTextMax,
            minLabel,
            maxLabel,
            rangeNameFormat
        } = props.props.resources;

        const locale = props.props.context.request.locale;
        const telemetry = props.props.telemetry;
        const validationErrorNaN = props.props.resources.validationErrorNotNumber;
        const validationErrorRange = props.props.resources.validationErrorNotRange;
        this._refineItemCommonProps = {
            telemetry,
            locale,
            placeholderTextMax,
            minLabel,
            maxLabel,
            rangeNameFormat,
            validationErrorNaN,
            validationErrorRange
        };

        this.state = {
            sortingState: {
                pending: false,
                dropdownOpen: false,
                selectedSortByOption: this.sortByDropdownOptions[0]
            }
        };

        this._renderRefiners = this._renderRefiners.bind(this);
        this._onClearRefiners = this._onClearRefiners.bind(this);
        this._getSortColumnFromSelectedOption = this._getSortColumnFromSelectedOption.bind(this);
        this._getSortingRefiner = this._getSortingRefiner.bind(this);

    }
    public render(): JSX.Element | null {
        const refineMenu: IRefineMenuViewProps = this._renderRefiners();
        const sortRefiner = this._getSortingRefiner();

        if (refineMenu.refiners) {
            return (
                
                <Node {...refineMenu.RefinerSectionContainer}>
                    <Node {...refineMenu.RefineMenuContainer}>
                        {refineMenu.refinersHeader}
                        {sortRefiner.sortByDropDown}
                        {refineMenu.refiners.map((submenu, index) => (
                            <React.Fragment key={index}>
                                {submenu}
                            </React.Fragment>
                        ))}
                    </Node>
                </Node>
            );
        }
        return null;
    }

    private _onClearRefiners = (): void => {
        const { context } = this.props.props;
        const requestContext = context && context.request;
        const actionContext = context && context.actionContext;

        if (!requestContext || !actionContext || !this.props.props.data.listPageState.result) {
            const error = `Refine menu cannot refine search criteria: ${!requestContext ? 'requestContext ' : ''} ${!actionContext ? 'actionContext ' : ''} could not be found`;
            this.props.props.telemetry.warning(error);
        }

        window.history.pushState({}, '', buildListPageUrl(getCurrentUrl(requestContext), []));
        transaction(() => {
            this.props.props.data.listPageState.result!.currentPageNumber = 0;
            this.props.props.data.listPageState.result!.activeFilters = [];
        });
    }

    private _getSortingRefiner = (): ISortByViewProps => {
        const { data, context, config } = this.props.props;

        const tempRangeTypeTODO = context.request.query && context.request.query.inputRange ? 'input' : 'slider';
        const sortingCritera = (data.listPageState.result && data.listPageState.result.sortingCritera) || undefined;
        
        let sortByValues:string[] = [];
        const activeRefiners = (data.listPageState.result && data.listPageState.result.activeFilters) || [];

        if (data.searchConfiguration.result) {
            if (sortingCritera && sortingCritera.Columns?.length) {
                data.searchConfiguration!.result!.forEach((searchConfiguration) => { 
                    sortingCritera.Columns!.forEach (sortingC => {
                        if (searchConfiguration.sortColumn.ColumnName === sortingC.ColumnName && searchConfiguration.sortColumn.IsDescending === sortingC.IsDescending) {
                            sortByValues.push(searchConfiguration.key);
                        }
                    })
                });
            }
        }

        let sortByDropdownOptions = [
            {
                RefinerSourceValue: 1,
                DataValueType: 5,
                Count: 8,
                RowNumber: 0,
                RefinerRecordId: 0,
                UnitText: 'Sort By',
                RightValueBoundString: sortOptions.sortByOptionRelevanceDesc,
                LeftValueBoundString: this.props.props.resources.sortByOptionRelevanceDesc
            },
            {
                RefinerSourceValue: 1,
                DataValueType: 5,
                Count: 8,
                RowNumber: 0,
                RefinerRecordId: 0,
                UnitText: 'Sort By',
                RightValueBoundString: sortOptions.sortByOptionNameAsc,
                LeftValueBoundString: this.props.props.resources.sortByOptionNameAsc
            },
            {
                RefinerSourceValue: 1,
                DataValueType: 5,
                Count: 8,
                RowNumber: 0,
                RefinerRecordId: 0,
                UnitText: 'Sort By',
                RightValueBoundString: sortOptions.sortByOptionNameDesc,
                LeftValueBoundString: this.props.props.resources.sortByOptionNameDesc
            },
            {
                RefinerSourceValue: 1,
                DataValueType: 5,
                Count: 8,
                RowNumber: 0,
                RefinerRecordId: 0,
                UnitText: 'Sort By',
                RightValueBoundString: sortOptions.sortByOptionPriceAsc,
                LeftValueBoundString: this.props.props.resources.sortByOptionPriceAsc
            },
            {
                RefinerSourceValue: 1,
                DataValueType: 5,
                Count: 8,
                RowNumber: 0,
                RefinerRecordId: 0,
                UnitText: 'Sort By',
                RightValueBoundString: sortOptions.sortByOptionPriceDesc,
                LeftValueBoundString: this.props.props.resources.sortByOptionPriceDesc
            }
        ];

        let sortBy: IProductRefinerHierarchy = {
            KeyName: 'SortBy',
            Values: sortByDropdownOptions
        } ;
        const dropdown = (
            <RefineSubmenu
                productRefinerHierarchy={sortBy}
                sortingCriteraValues={sortByValues}
                selectedRefinerValues={activeRefiners}
                refineItemCommonProps={this._refineItemCommonProps}
                minValueSliderThumbAriaLabel={this.props.props.resources.minValueSliderThumbAriaLabel}
                maxValueSliderThumbAriaLabel={this.props.props.resources.maxValueSliderThumbAriaLabel}
                key={'sort-by-1'}
                onUpdateRefiners={this._updateDropDown}
                urlBuilder={this._buildRefinerUrl}
                isDisabled={false}
                isExpandedOnInitialLoad={false}
                tempRangeTypeTODO={tempRangeTypeTODO}
                context={context}
                moduleId={this.props.props.id}
                moduleTypeName={this.props.props.typeName}
                refinersToBeUpdated={config.refinersToBeUpdated || ''}
            />
            );
        return {
            SortingContainer: { className: 'ms-search-result-container__Sort-by-category' },
            sortByDropDown: dropdown
        };
    }

    private _updateDropDown = (notification: IRefineItemToggleNotification): void => {
        let sortColumn = this._getSortColumnFromSelectedOption(notification.productRefinerValue.RightValueBoundString || '')

        window.history.pushState({}, '', buildListPageUrl(getCurrentUrl(this.props.props.context.request), undefined, [sortColumn], undefined));
        transaction(() => {
            this.props.props.data.listPageState.result!.currentPageNumber = 0;
            this.props.props.data.listPageState.result!.sortingCritera = { Columns: [sortColumn] };
        });

        this.setState({
            sortingState: {
                dropdownOpen: false,
                pending: false,
                selectedSortByOption: this.sortByDropdownOptions[0]
            }
        });
    }

    private _getSortColumnFromSelectedOption = (type: string): SortColumn => {
        const { data } = this.props.props;

        if (!data.searchConfiguration.result) {
            return {};
        }

        const mappedConfiguration = data.searchConfiguration.result.find((searchConfiguration) => { return searchConfiguration.key === type; });

        if (mappedConfiguration) {
            return mappedConfiguration.sortColumn;
        }

        return {};
    }

    private _renderRefiners = (): IRefineMenuViewProps => {
        const { data, context, config } = this.props.props;

        const tempRangeTypeTODO = context.request.query && context.request.query.inputRange ? 'input' : 'slider';
        const validRefiners = data.refiners.result && data.refiners.result.filter(refiner => {
            return refiner.Values.length > 0;
        });
        const expandedMenusOnLoad = config.expandedMenusOnLoad || 'all';
        const activeRefiners = (data.listPageState.result && data.listPageState.result.activeFilters) || [];
        const refinersHeader =
            (
                <div className='refine-menu-header'>
                      <div className='refine-menu-close'>
                        <Button
                            className={'refine-menu-close-button'}
                            // @ts-ignore
                            onClick={this.props.toggleModal}
                        >{'x'}
                        </Button>
                    </div>

                    <div className='refine-menu-header-text'>Refine</div>
                    <div className='refine-menu-header-action'>
                        <Button
                            className={'refine-menu-header-action-link'}
                            aria-label={'Clear'}
                            // tslint:disable-next-line: react-this-binding-issue
                            onClick={this._onClearRefiners}
                        >{'Clear'}
                        </Button>
                    </div>
                </div>
            );

        
        let orderedRefiners: IProductRefinerHierarchy[] = [];

        if (validRefiners && validRefiners.length){
                let refinerCat = validRefiners.find(refiner => refiner.KeyName === 'Refiner Category');
                refinerCat && orderedRefiners.push(refinerCat)
            
            validRefiners.forEach(refiner => {
                
                if (refiner.KeyName !== 'Refiner Category') {
                    orderedRefiners.push(refiner)
                }
            })
        }
        const subMenus = orderedRefiners && orderedRefiners.map((productRefinerHierarchy: IProductRefinerHierarchy, index: number) => {
            const hiddenRefinersList = config.refinersToBeHidden && config.refinersToBeHidden.toLowerCase().split(',');
            if (productRefinerHierarchy.KeyName !== 'Category' && (hiddenRefinersList ? (hiddenRefinersList.indexOf(productRefinerHierarchy.KeyName!.toLowerCase()) === -1 && productRefinerHierarchy.KeyName!.toLowerCase() !== 'rating') : true)) {
                let isExpanded: boolean;
                switch (expandedMenusOnLoad) {
                    case 'none':
                        isExpanded = false;
                        break;
                    case 'first':
                        isExpanded = index === 0;
                        break;
                    case 'all':
                    default:
                        isExpanded = true;
                }
                if (context.request && context.request.device && context.request.device.Type === 'Mobile') {
                    if (productRefinerHierarchy.KeyName === "Refiner Category") {
                        isExpanded = true;
                    }
                }

                return (
                    <RefineSubmenu
                        productRefinerHierarchy={productRefinerHierarchy}
                        selectedRefinerValues={activeRefiners}
                        refineItemCommonProps={this._refineItemCommonProps}
                        minValueSliderThumbAriaLabel={this.props.props.resources.minValueSliderThumbAriaLabel}
                        maxValueSliderThumbAriaLabel={this.props.props.resources.maxValueSliderThumbAriaLabel}
                        key={index}
                        onUpdateRefiners={this._onUpdateRefiners}
                        urlBuilder={this._buildRefinerUrl}
                        isDisabled={false}
                        isExpandedOnInitialLoad={isExpanded}
                        tempRangeTypeTODO={tempRangeTypeTODO}
                        context={context}
                        moduleId={this.props.props.id}
                        moduleTypeName={this.props.props.typeName}
                        refinersToBeUpdated={config.refinersToBeUpdated || ''}
                    />
                );
            } else {
                return undefined;
            }
        });
        return {
            RefineMenuContainer: { className: 'ms-search-result-container__refine-menu' },
            RefinerSectionContainer: { className: 'ms-search-result-container__refiner-section' },
            refinersHeader: refinersHeader,
            refiners: subMenus
        };
    }

    private _buildRefinerUrl = (itemToggleNotification: IRefineItemToggleNotification): string => {
        if (this.props.props.data.listPageState.result) {
            const newRefinementCriteria = getUpdatedRefinementCriteria(itemToggleNotification, this.props.props.data.listPageState.result.activeFilters || []);

            return buildListPageUrl(getCurrentUrl(this.props.props.context.request), newRefinementCriteria);
        } else {
            this.props.props.telemetry.warning(`[buildRefinerQueryString]List Page State Not available, unable to build refiner URL`);
            return '';
        }
    }

    private _onUpdateRefiners = (itemToggleNotification: IRefineItemToggleNotification): void => {
        const { context } = this.props.props;
        const requestContext = context && context.request;
        const actionContext = context && context.actionContext;

        if (!requestContext || !actionContext || !this.props.props.data.listPageState.result) {
            const error = `Refine menu cannot refine search criteria: ${!requestContext ? 'requestContext ' : ''} ${!actionContext ? 'actionContext ' : ''} could not be found`;
            this.props.props.telemetry.warning(error);
        }

        const updatedRefinementCriteria = getUpdatedRefinementCriteria(itemToggleNotification, this.props.props.data.listPageState.result && this.props.props.data.listPageState.result.activeFilters || []);

        window.history.pushState({}, '', buildListPageUrl(getCurrentUrl(this.props.props.context.request), updatedRefinementCriteria));
        transaction(() => {
            this.props.props.data.listPageState.result!.currentPageNumber = 0;
            this.props.props.data.listPageState.result!.activeFilters = updatedRefinementCriteria;
        });
    }
}
